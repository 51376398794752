// React
import React from 'react';

// Gatsby
import { graphql, useStaticQuery } from 'gatsby';

// Emotion / styling
import styled from '@emotion/styled';
import s from 'src/styles';

// Packages

// Utilities
import { useTheme } from 'hooks';
import { localize, localizeLink } from 'utilities';
import { transitionNavigate } from 'transition';

// Components
import SEO from 'components/seo';
import TilePattern from 'components/tilePattern';
import Button from 'components/button';

const NotFoundPageComponent = props => {
    const {
        content: { seoMetaTags, title, backToShopTitle },
        location,
        pageContext: { locale, initialTheme },
        shop,
    } = props;

    // Set theme
    const theme = useTheme(initialTheme);

    return (
        <s.layout.PageContainer>
            <Error {...{ theme }}>
                <SEO {...{ seoMetaTags, locale }} />
                <TilePattern {...{ theme, pattern: 'hero' }} />
                <Subtitle>{title}</Subtitle>
                <div>
                    <Button
                        {...{
                            label: backToShopTitle,
                            theme,
                            arrow: true,
                            onClick() {
                                transitionNavigate({
                                    from: location,
                                    to: localizeLink(`/${shop.slug}/`, locale),
                                });
                            },
                        }}
                    />
                </div>
            </Error>
        </s.layout.PageContainer>
    );
};

const Error = styled.div`
    ${props => s.layout.fullWidthBackground(props.theme.default())};

    ${s.responsive.property('padding-top', {
        0: '24vh',
        10: '26vh',
        20: '28vh',
        30: '40vh',
        40: '46vh',
    })};

    position: relative;
    display: flex;
    flex-direction: column;
    color: ${props => props.theme.text()};
    height: 100.1vh;
`;

const Subtitle = styled.div`
    ${s.grid.columns('width', {
        0: 4,
        10: 3.3,
        20: 6,
        30: 10,
        40: 8,
    })};

    z-index: 9999;

    ${s.fonts.style('h3')};

    position: relative;
    pointer-events: none;
    margin-bottom: 30px;
`;

const NamedNotFoundPageComponent = props => {
    const { locale } = props.pageContext;
    const { allDatoCmsPage404, datoCmsPageShop } = useStaticQuery(graphql`
        query {
            datoCmsPageShop {
                slug
            }
            allDatoCmsPage404 {
                edges {
                    node {
                        locale
                        title
                        backToShopTitle
                        seoMetaTags {
                            ...SEO
                        }
                    }
                }
            }
        }
    `);

    return (
        <NotFoundPageComponent
            {...{
                content: localize(allDatoCmsPage404, locale),
                shop: datoCmsPageShop,
                ...props,
            }}
        />
    );
};
export default NamedNotFoundPageComponent;
